import React, {
  memo,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import { useIntl } from 'react-intl';
import { Icon, FormLabel } from '@dnb/eufemia/components';
import { minimize_medium, chatbot_medium, close } from '@dnb/eufemia/icons';
import { debounce } from '@dnb/eufemia/shared/helpers';
import { useMedia } from '@dnb-shared-libs/responsive';
import ChatBotIframe from './ChatBotIframe';
import ChatBotStateContext from './ChatBotStateContext';
import {
  enableBodyScrollOnMobileAndTablet,
  disableBodyScrollOnMobileAndTablet,
} from './scrollUtils';
import {
  ChatBotWrapper,
  ChatBotMenu,
  ChatBotOverlayTop,
  ChatBotOverlayButtons,
} from './styles';
import { ChatBotOpenStateType } from './types';
import useChatBot from './useChatBot';

const SCROLL_WAITING_TIME = 500;
const SCROLL_MINIMUM_BOTTOM = 144;
const ChatBotContent = () => {
  const [closeButtonNumber, setCloseButtonNumber] = useState(0);
  const [syncMobileBottomPosition, setSyncMobileBottomPosition] =
    useState(false);
  const [isTopOrBottomOfPage, setIsTopOrBottomOfPage] = useState(true);
  const [isHide, setIsHide] = useState(false);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  const timerRef = useRef<number>();
  const [showText, setShowText] = useState(false);
  const [chatTimeout, setChatTimeout] = useState(false);
  const chatFrame = useRef<HTMLIFrameElement>(null);
  const { isMobile } = useMedia();

  const { openState, customerSegment } = useContext(ChatBotStateContext);
  const { toggleChatBot, exitChatBot } = useChatBot({
    customerSegment,
  });

  const intl = useIntl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const syncMobileFloatingButton = debounce(() => {
      const winScroll = window.pageYOffset;
      const oneScreenHeight = document.documentElement.clientHeight;
      const totalPageHeight = document.body.clientHeight;
      const distanceBottom = totalPageHeight - oneScreenHeight - winScroll;

      distanceBottom <= SCROLL_MINIMUM_BOTTOM
        ? setSyncMobileBottomPosition(true)
        : setSyncMobileBottomPosition(false);
      setShowText(false);
      setIsHide(true);
      setIsTopOrBottomOfPage(window.pageYOffset <= 0 || distanceBottom <= 0);

      // show chatbot button  if not scrolling
      clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        setIsHide(false);
      }, SCROLL_WAITING_TIME);
    }, 16);

    window.addEventListener('scroll', syncMobileFloatingButton);
    window.addEventListener('resize', syncMobileFloatingButton);
    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('scroll', syncMobileFloatingButton);
      window.removeEventListener('resize', syncMobileFloatingButton);
    };
  }, []);

  useEffect(() => {
    const handleIframeMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        //chatbot when minimised timed out
        if (data.message === 'closeOnNavigation') {
          setChatTimeout(true);
        } else if (data.message === 'closeOnSingleClick') {
          setCloseButtonNumber(1);
        } else if (data.message === 'textAreaFocused') {
          setIsTextAreaFocused(data.data);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    if (openState.type === ChatBotOpenStateType.OPEN) {
      disableBodyScrollOnMobileAndTablet(isMobile);
    } else if (openState.type === ChatBotOpenStateType.MINIMIZED) {
      enableBodyScrollOnMobileAndTablet(isMobile);
    }
  }, [openState.type, isMobile]);

  const closeChat = useCallback(() => {
    chatFrame?.current?.contentWindow.postMessage(
      JSON.stringify({
        error: false,
        message: 'closeChat',
      }),
      '*'
    );
  }, []);

  const chatBotMenuKeyPressHandler = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
        toggleChatBot();
      }
    },
    [toggleChatBot]
  );

  const buttonToggleChat = useCallback(
    (e: { event: MouseEvent }) => {
      e.event.preventDefault();
      e.event.stopPropagation();

      toggleChatBot();
    },
    [toggleChatBot]
  );

  const exitChat = useCallback(
    (e: { event: MouseEvent }) => {
      e.event.preventDefault();
      e.event.stopPropagation();

      if (!chatFrame.current) return;
      closeChat();

      if (closeButtonNumber < 1) {
        setCloseButtonNumber(1);
      } else {
        exitChatBot();
        setCloseButtonNumber(0);
        enableBodyScrollOnMobileAndTablet(isMobile);
        setChatTimeout(false);
      }
    },
    [exitChatBot, closeButtonNumber, closeChat, isMobile]
  );

  const stopPropagation = useCallback(
    (e: KeyboardEvent<HTMLButtonElement>) => e.stopPropagation(),
    []
  );

  const btnTextVisible = showText || isTopOrBottomOfPage;
  const minimizeChat = openState.type === ChatBotOpenStateType.MINIMIZED;

  return (
    <ChatBotWrapper role="complementary">
      {openState.type !== ChatBotOpenStateType.CLOSED && (
        <ChatBotMenu
          minimizeChat={minimizeChat}
          chatTimeout={chatTimeout}
          aria-expanded={!minimizeChat}
          btnTextVisible={btnTextVisible}
          isTextAreaFocused={isTextAreaFocused}
          isHide={isHide}
          syncMobileBottomPosition={syncMobileBottomPosition}
          aria-label="This is the overlay for chatbot menu"
          onClick={toggleChatBot}
          onKeyPress={chatBotMenuKeyPressHandler}
          tabIndex={0}
        >
          <ChatBotOverlayTop
            minimizeChat={minimizeChat}
            chatTimeout={chatTimeout}
            btnTextVisible={btnTextVisible}
            isTextAreaFocused={isTextAreaFocused}
          >
            <ChatBotOverlayButtons
              icon={!minimizeChat && minimize_medium}
              icon_position="top"
              variant="tertiary"
              on_click={buttonToggleChat}
              onKeyPress={stopPropagation}
              tabIndex={0}
              text={
                !minimizeChat && intl.formatMessage({ id: 'chatbot.minimise' })
              }
            />
            <Icon icon={chatbot_medium} className="chatbot-icon--style" />
            {minimizeChat && btnTextVisible && (
              <FormLabel for_id="minimize_chatbot">Chatbot</FormLabel>
            )}
            <ChatBotOverlayButtons
              icon={!minimizeChat && close}
              icon_position="top"
              variant="tertiary"
              on_click={exitChat}
              onKeyPress={stopPropagation}
              tabIndex={0}
              text={
                !minimizeChat && intl.formatMessage({ id: 'chatbot.close' })
              }
            />
          </ChatBotOverlayTop>

          <div
            style={{
              width: '100%',
              height: 'calc(100% - 4.25rem)',
              display: minimizeChat ? 'none' : 'block',
            }}
          >
            <ChatBotIframe
              ref={chatFrame}
              newChat={openState.isNew}
              locale={intl.locale}
            />
          </div>
        </ChatBotMenu>
      )}
    </ChatBotWrapper>
  );
};

export default memo(ChatBotContent);
