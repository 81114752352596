import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import ChatBotContext from './ChatBotContext';
import ChatBotStateContext from './ChatBotStateContext';
import { getNavigationType } from './navigation';
import {
  clearStorageValue,
  getStorageValue,
  setStorageValue,
  StorageKeys,
} from './storage';
import { ChatBotOpenState, ChatBotOpenStateType } from './types';

const oneHour = 1000 * 60 * 60;

type ChatBotContextProviderProps = {
  chatFrameUrl: string;
  children: ReactNode;
  initialAutoOpen?: boolean;
};

const ChatBotContextProvider = ({
  children,
  chatFrameUrl,
  initialAutoOpen,
}: ChatBotContextProviderProps) => {
  const [customerSegment, setCustomerSegment] = useState<
    'private' | 'corporate' | undefined
  >(undefined);

  const [openState, setOpenState] = useState<ChatBotOpenState>(() => {
    const openedAt = getStorageValue(StorageKeys.OPENED_AT);

    // Auto open on page load when it is less than 1 hour since it was opened
    // On manual reload of the page, we do not auto open the chat either,
    // to lessen the amount of chat sessions started
    const shouldAutoOpen =
      initialAutoOpen ||
      (openedAt &&
        Date.now() - parseInt(openedAt, 10) <= oneHour &&
        getNavigationType() !== 'reload');

    // If there is an opened at value, but we do not want to auto open, we will clear the storage value
    if (!shouldAutoOpen && openedAt) {
      clearStorageValue(StorageKeys.OPENED_AT);
    } else if (initialAutoOpen && !openedAt) {
      setStorageValue(StorageKeys.OPENED_AT, `${Date.now()}`);
    }

    return {
      type: shouldAutoOpen
        ? initialAutoOpen
          ? ChatBotOpenStateType.OPEN
          : ChatBotOpenStateType.MINIMIZED
        : ChatBotOpenStateType.CLOSED,
      isNew: !shouldAutoOpen || (initialAutoOpen && !openedAt),
    };
  });

  const showChatBot = useCallback(
    (customerSegment?: 'private' | 'corporate') => {
      setCustomerSegment(customerSegment);
      setOpenState((currentOpenState) => ({
        type: ChatBotOpenStateType.OPEN,
        isNew:
          currentOpenState.type === ChatBotOpenStateType.CLOSED
            ? true
            : currentOpenState.isNew,
      }));
      setStorageValue(StorageKeys.OPENED_AT, `${Date.now()}`);
    },
    []
  );

  const minimizeChatBot = useCallback(() => {
    setOpenState((currentOpenState) => ({
      ...currentOpenState,
      type: ChatBotOpenStateType.MINIMIZED,
    }));
  }, []);

  const toggleChatBot = useCallback(
    (customerSegment?: 'private' | 'corporate') => {
      setCustomerSegment(customerSegment);
      setOpenState((currentOpenState) => {
        if (currentOpenState.type === ChatBotOpenStateType.OPEN) {
          return {
            ...currentOpenState,
            type: ChatBotOpenStateType.MINIMIZED,
          };
        } else {
          let isNew = currentOpenState.isNew;

          if (currentOpenState.type === ChatBotOpenStateType.CLOSED) {
            setStorageValue(StorageKeys.OPENED_AT, `${Date.now()}`);
            isNew = true;
          }

          return {
            type: ChatBotOpenStateType.OPEN,
            isNew,
          };
        }
      });
    },
    []
  );

  const exitChatBot = useCallback(() => {
    setOpenState({
      type: ChatBotOpenStateType.CLOSED,
      isNew: true,
    });
    clearStorageValue(StorageKeys.OPENED_AT);
  }, []);

  const chatbotContextValue = useMemo(
    () => ({
      showChatBot,
      minimizeChatBot,
      toggleChatBot,
      exitChatBot,
    }),
    [showChatBot, minimizeChatBot, toggleChatBot, exitChatBot]
  );

  const chatbotStateContextValue = useMemo(
    () => ({
      chatFrameUrl,
      customerSegment,
      openState,
    }),
    [chatFrameUrl, customerSegment, openState]
  );

  return (
    <ChatBotContext.Provider value={chatbotContextValue}>
      <ChatBotStateContext.Provider value={chatbotStateContextValue}>
        {children}
      </ChatBotStateContext.Provider>
    </ChatBotContext.Provider>
  );
};

export default memo(ChatBotContextProvider);
