import { MouseEvent, useCallback, useContext, useMemo } from 'react';
import ChatBotContext from './ChatBotContext';

type UseChatBotSettings = {
  customerSegment?: 'private' | 'corporate';
};

type EufemiaMouseEvent = {event: MouseEvent}
type PotentialMouseEvent = MouseEvent | EufemiaMouseEvent

const isEufemiaEvent = (e: PotentialMouseEvent): e is EufemiaMouseEvent => {
  const eventCheck = e as EufemiaMouseEvent

  return !!(eventCheck?.event?.nativeEvent && eventCheck.event.preventDefault)
}

// User facing hook for chatbot for opening/closing the chatbot
const useChatBot = (settings?: UseChatBotSettings) => {
  const customerSegment = settings?.customerSegment;

  const chatBotContext = useContext(ChatBotContext);

  const showChatBot = useCallback(
    () => chatBotContext.showChatBot(customerSegment),
    // eslint mistakenly says we are missing dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatBotContext.showChatBot, customerSegment]
  );
  const toggleChatBot = useCallback(
    (e?: PotentialMouseEvent) => {
      let preventableEvent: MouseEvent

      if(isEufemiaEvent(e)) {
        preventableEvent = e.event
      } else {
        preventableEvent = e
      }

      preventableEvent?.preventDefault?.()

      return chatBotContext.toggleChatBot(customerSegment)
    },
    // eslint mistakenly says we are missing dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatBotContext.toggleChatBot, customerSegment]
  );

  const minimizeChatBot = chatBotContext.minimizeChatBot;
  const exitChatBot = chatBotContext.exitChatBot;

  return useMemo(
    () => ({
      showChatBot,
      toggleChatBot,
      minimizeChatBot,
      exitChatBot,
    }),
    [showChatBot, toggleChatBot, minimizeChatBot, exitChatBot]
  );
};

export default useChatBot;
