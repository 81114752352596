import type { ComponentType, ForwardRefRenderFunction } from 'react'
import React, { useContext } from 'react'
import { IntlProvider, IntlContext } from 'react-intl'
import type { TranslationsLookup } from './types'

export interface IntlProps {
  locale?: string
  defaultLocale?: string
}

const getDisplayName = (WrappedComponent: ComponentType, forwardRef: boolean) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || ''

  if (forwardRef && !displayName) {
    // ref forwarding components has their named component within a special react object wrapper,
    // where the render property is the original component
    const Component = WrappedComponent as any

    return Component?.render?.displayName || Component?.render?.name || ''
  }

  return displayName
}

const legacyLocaleMapping = {
  no: 'nb-NO',
  en: 'en-GB',
  sv: 'sv-SE'
}

export function withIntlProvider<Props, Ref>(
  WrappedComponent: ComponentType<Props>,
  componentTranslations: TranslationsLookup,
  forwardRef?: boolean
) {
  const ComponentWithIntlProvider: ForwardRefRenderFunction<Ref, Props & IntlProps> = (
    props,
    ref
  ) => {
    const { locale, defaultLocale, ...restProps } = props
    const childComponentProps = restProps as Props
    const parentIntl = useContext(IntlContext)
    const fallbackLocale = 'nb-NO'

    let activeLocale =
      locale || parentIntl?.locale || defaultLocale || parentIntl?.defaultLocale || fallbackLocale

    if (activeLocale?.length === 2 && legacyLocaleMapping[activeLocale]) {
      activeLocale = legacyLocaleMapping[activeLocale]
    }

    const intlConfig: React.ComponentProps<typeof IntlProvider> = parentIntl
      ? {
          ...parentIntl,
          messages: {
            ...componentTranslations?.[activeLocale],
            ...parentIntl.messages
          },
          locale: activeLocale
        }
      : {
          locale: activeLocale,
          defaultLocale,
          messages: {
            ...componentTranslations?.[activeLocale]
          }
        }

    if (forwardRef) {
      return (
        <IntlProvider {...intlConfig}>
          <WrappedComponent ref={ref} {...childComponentProps} />
        </IntlProvider>
      )
    }

    return (
      <IntlProvider {...intlConfig}>
        <WrappedComponent {...childComponentProps} />
      </IntlProvider>
    )
  }

  const componentDisplayName = getDisplayName(WrappedComponent, forwardRef)

  ComponentWithIntlProvider.displayName = componentDisplayName
    ? `withIntlProvider(${componentDisplayName})`
    : 'withIntlProvider'

  if (forwardRef) {
    return React.forwardRef(ComponentWithIntlProvider)
  }

  return ComponentWithIntlProvider
}
