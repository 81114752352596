export enum ChatBotOpenStateType {
  CLOSED = 'closed',
  MINIMIZED = 'minimized',
  OPEN = 'open',
}

export type ChatBotOpenState = {
  type: ChatBotOpenStateType;
  isNew: boolean;
};


export interface MinimizeChat {
  minimizeChat: boolean
}

export interface BottomPosition {
  syncMobileBottomPosition: boolean
  isHide: boolean
  btnTextVisible: boolean
}

export interface TextAreaFocused {
  isTextAreaFocused: boolean
}

export interface ChatTimeout {
  chatTimeout: boolean
}

export interface TextVisible {
  btnTextVisible: boolean
}
