import React, { forwardRef, useContext } from 'react'
import ChatBotStateContext from './ChatBotStateContext'
import { ChatBotIframeWrapper } from './styles'

type ChatBotIframeProps = {
  newChat: boolean
  locale: string
}

const ChatBotIframe = (
  { newChat, locale }: ChatBotIframeProps,
  ref: React.Ref<HTMLIFrameElement>
) => {
  const { customerSegment, chatFrameUrl } = useContext(ChatBotStateContext)
  return (
    <ChatBotIframeWrapper aria-label="This is the chatbot content ">
      <iframe
        ref={ref}
        title="Chatbot chat content"
        name="Chatbot chat content"
        src={`${chatFrameUrl}&newChat=${newChat}&seg=${
          customerSegment === 'corporate' ? 'bm' : 'pm'
        }&la=${locale}`}
        allow="autoplay"
        allowFullScreen
      />
    </ChatBotIframeWrapper>
  )
}

export default forwardRef(ChatBotIframe)
