/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import * as hasOwn from 'object.hasown'

if (!Object.hasOwn) {
	hasOwn.shim();
}

export { wrapPageElement } from './gatsby/wrapPageElement'

/*
 the code below in especially in functions shouldReport, onInitialClientRender, onPreRouteUpdate
 will only track page changes if the url changes,
 not when a query parameter changes which will be captured in dynatrace"
 */

let initialClientRenderDone = false
const shouldReport = () => {
  //check fo dynatrace object is not undefined
  return typeof window.dtrum !== `undefined` && initialClientRenderDone
}

//setting initial client render for dynatrace
export const onInitialClientRender = () => {
  initialClientRenderDone = true
}

//Capture action event on Navigation for dynatrace
export const onPreRouteUpdate = function () {
  if (shouldReport()) {
    const actionId = window.dtrum && window.dtrum.enterAction('Navigate', 'gatsby')
    window.dtActionId = actionId
  }
}
export const onRouteUpdate = ({ prevLocation }) => {
  // in order to use our own focus management by using applyPageFocus
  // we have to disable the focus management from Reach Router
  // More info: why we have to have the tabindex https://reach.tech/router/accessibility
  // More info: The div is necessary to manage focus https://github.com/reach/router/issues/63#issuecomment-395988602
  try {
    const elem = document.querySelector('#gatsby-focus-wrapper')
    if (elem) {
      elem.removeAttribute('tabindex')
      elem.removeAttribute('role')
    }
  } catch (e) {
    console.log(e) // eslint-disable-line no-console
  }

  //Leave action event for dynatrace and uassigning the dtActionId
  if (shouldReport()) {
    if (window.dtActionId) {
      window.dtrum.leaveAction(window.dtActionId)
      window.dtActionId = null
    }
  }
  // if previous location is not null
  // witch means that this was an page change/switch
  //  then we apply the page content focus for accissibility
  if (prevLocation) {
    // applyPageFocus('content')
  }
  // Trigger adobe target view
  let viewName = window.location.pathname || 'home'
  // Sanitize viewName to get rid of any trailing symbols derived from URL
  if (viewName.startsWith('#') || viewName.startsWith('/')) {
    viewName = viewName.slice(1)
  }
  if (typeof window?.adobe?.target?.triggerView === 'function') {
    window.adobe.target.triggerView(viewName)
  }
}

export const shouldUpdateScroll = ({ routerProps }) => {
  const shouldUpdateScroll = !routerProps.location.state?.saveScrollPosition
  return shouldUpdateScroll
}
