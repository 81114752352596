import { createContext } from 'react';

const notInitializedWarning = 'ChatbotContextProvider is not initialized';

const ChatBotContext = createContext({
  // eslint-disable-next-line no-unused-vars
  showChatBot: (customerSegment?: 'private' | 'corporate') => {
    console.error(notInitializedWarning);
  },
  minimizeChatBot: () => {
    console.error(notInitializedWarning);
  },
  // eslint-disable-next-line no-unused-vars
  toggleChatBot: (customerSegment?: 'private' | 'corporate') => {
    console.error(notInitializedWarning);
  },
  exitChatBot: () => {
    console.error(notInitializedWarning);
  },
});

export default ChatBotContext;
