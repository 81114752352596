export enum StorageKeys {
  OPENED_AT = 'opened-at',
}

const isClient = typeof window !== `undefined`;
const getStorageKey = (name: StorageKeys) => `dnb-chatbot-${name}`;

export const getStorageValue = (name: StorageKeys) => {
  const key = getStorageKey(name);

  if (!isClient) {
    return null;
  }

  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error(`Error accessing local storage for DNB chatbot\n${e.stack}`);

    return null;
  }
};

export const setStorageValue = (name: StorageKeys, value: string) => {
  const key = getStorageKey(name);

  if (!isClient) {
    return false;
  }

  try {
    localStorage.setItem(key, value);

    return true;
  } catch (e) {
    console.error(`Error accessing local storage for DNB chatbot\n${e.stack}`);

    return false;
  }
};

export const clearStorageValue = (name: StorageKeys) => {
  const key = getStorageKey(name);

  if (!isClient) {
    return false;
  }

  try {
    localStorage.removeItem(key);

    return true;
  } catch (e) {
    console.error(`Error accessing local storage for DNB chatbot\n${e.stack}`);

    return false;
  }
};
