import { Button } from '@dnb/eufemia/components'
import styled from '@emotion/styled'
import { media } from '@dnb-shared-libs/responsive'
import { MinimizeChat, ChatTimeout, BottomPosition, TextAreaFocused, TextVisible } from './types'

export const ChatBotWrapper = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  font-family: var(--font-family-default);
  z-index: 200;
  width: 100%;
  display: flex;
  left: 0;
  right: 0;
  justify-content: flex-end;
  align-items: center;
`

export const ChatBotMenu = styled.div<
  MinimizeChat & ChatTimeout & BottomPosition & TextAreaFocused
>`
  z-index: 202;
  top: ${(props) => (props.minimizeChat ? '' : '13.5rem')};
  width: ${(props) =>
    props.minimizeChat && !props.btnTextVisible ? '3rem' : props.minimizeChat ? '9rem' : '23rem'};
  position: fixed;
  bottom: ${(props) =>
    props.minimizeChat && props.syncMobileBottomPosition
      ? '18rem'
      : props.minimizeChat
      ? '1rem'
      : '0'};
  right: '0';
  border-bottom: 0px;
  opacity: 1;
  transform: scale(1);
  background-color: rgba(255, 255, 255, 0);
  border-top-right-radius: ${(props) => (props.minimizeChat ? '0px' : '10px')};
  border-top-left-radius: ${(props) => (props.minimizeChat ? '0px' : '10px')};
  border-radius: ${(props) => (props.minimizeChat ? '50px' : '0px')};
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.2);
  outline: none;

  ${(props) => media.tabletDevice`
  top: ${props.minimizeChat ? '' : '5.5rem'};
  right:${props.minimizeChat ? '6.25rem' : '0px'};
  `}

  ${(props) => media.phone`
  top: ${props.minimizeChat ? '' : '0'};
  bottom: ${
    props.minimizeChat && props.syncMobileBottomPosition
      ? '18rem'
      : props.minimizeChat
      ? '5rem'
      : '0'
  };
  width:${
    props.minimizeChat && !props.btnTextVisible ? '3rem' : props.minimizeChat ? '9rem' : '100%'
  };
  right:${props.minimizeChat ? '1rem' : 'inherit'};
  opacity: ${props.isHide ? 0 : 1};
  `}

  ${(props) => media.smallDesktop`
  right:${props.minimizeChat ? '1rem' : '0px'};
  `}

  ${(props) => media.largeDesktop`
  right:${props.minimizeChat ? '1rem' : '0px'};
  margin-right:${props.minimizeChat ? '1rem' : ''};
  `}

  ${(props) => media.xLargeDesktop`
  margin-right:${props.minimizeChat ? '1rem' : '0px'};
  right:${props.minimizeChat ? '' : '0px'};
  `}

  ${(props) => media.smallWide`
  top: ${props.minimizeChat ? '' : '9.5rem'};
  right:${props.minimizeChat ? '' : '5rem'};
  margin-right:${props.minimizeChat ? '1rem' : ''};
  `}

  ${(props) => media.wideScreen`
  top: ${props.minimizeChat ? '' : '9.5rem'};
  right:${props.minimizeChat ? '' : '9.5rem'};
  margin-right:${props.minimizeChat ? '1rem' : ''};
  `}
 > div {
    background-color: ${(props) =>
      (props.minimizeChat && !props.chatTimeout) || props.isTextAreaFocused
        ? 'var(--color-sea-green)'
        : 'var(--color-white)'};
    color: ${(props) =>
      (props.minimizeChat && !props.chatTimeout) || props.isTextAreaFocused
        ? 'var(--color-white)'
        : 'var(--color-sea-green)'};
    > button {
      background-color: inherit;
      color: inherit;
    }
    > label {
      background-color: inherit;
      color: inherit;
      margin-right: 0;
    }
    .dnb-form-label:hover {
      color: var(--color-white);
    }
  }
  &:hover > div {
    background-color: var(--color-sea-green);
    color: var(--color-white);
    > button {
      background-color: inherit;
      color: inherit;
    }
    > label {
      background-color: inherit;
      color: inherit;
    }
    .dnb-form-label:hover {
      color: var(--color-white);
    }
  }
`

export const ChatBotOverlayTop = styled.div<
  MinimizeChat & ChatTimeout & TextVisible & TextAreaFocused
>`
  border: ${(props) => (!props.minimizeChat ? '1px solid var(--color-sea-green)' : '0px')};
  border-bottom: ${(props) =>
    !props.minimizeChat ? '1px solid var(--color-mint-green-50)' : '0px'};
  display: flex;
  justify-content: space-between;
  padding: ${(props) => (!props.minimizeChat ? '6px' : props.btnTextVisible ? '8px 16px' : '8px')};
  border-top-right-radius: ${(props) => (props.minimizeChat ? '0px' : '6px')};
  border-top-left-radius: ${(props) => (props.minimizeChat ? '0px' : '6px')};
  border-radius: ${(props) => (props.minimizeChat ? '50px' : '')};
  background-color: ${(props) =>
    props.minimizeChat && !props.chatTimeout ? `var(--color-sea-green)` : `var(--color-white)`};
  color: ${(props) =>
    props.minimizeChat && !props.chatTimeout ? `var(--color-white)` : `var(--color-sea-green)`};
  > button {
    display: ${(props) => (props.minimizeChat ? 'none' : 'inherit')};
  }
  > label {
    color: ${(props) =>
      props.minimizeChat && !props.chatTimeout ? `var(--color-white)` : `var(--color-sea-green)`};
    background: ${(props) =>
      props.minimizeChat && !props.chatTimeout ? `var(--color-sea-green)` : `var(--color-white)`};
    padding-left: 3px;
    padding-top: 6px;
    font-size: var(--font-size-basis);

    &:hover {
      color: var(--color-white);
      background-color: var(--color-sea-green);
      border: none;
    }
    ${(props) => media.phone`
    padding: ${!props.minimizeChat && !props.btnTextVisible ? '10px 12px 10px 8px' : '8px'};
  `}
  }
  .chatbot-icon--style {
    font-size: var(--spacing-large);
    margin-top: ${(props) => (!props.minimizeChat ? '9px' : '')};
    margin-right: ${(props) => (!props.minimizeChat ? '25px' : '')};
  }
`
export const ChatBotOverlayButtons = styled(Button)`
  &&& {
    padding: 0 0.5rem;
  }

  && > .dnb-button__icon {
    font-size: 1rem;
  }

  && > .dnb-button__text {
    font-size: var(--font-size-small);
  }
`

export const ChatBotIframeWrapper = styled.div`
  height: 100%;
  > iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
  border-left: 1px solid var(--color-sea-green);
  border-right: 1px solid var(--color-sea-green);
`
