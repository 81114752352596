import React from 'react';
import ChatBotContextProvider from '../src/ChatBotContextProvider';

const ChatbotProviderWrapper = ({ element }, { chatFrameUrl }) => (
  <ChatBotContextProvider chatFrameUrl={chatFrameUrl} >
    {element}
  </ChatBotContextProvider>
);

export default ChatbotProviderWrapper;
