import React, { memo } from 'react';
import { IntlProvider } from 'react-intl';
import ChatBotContent from './ChatBotContent'
import { translationsChatBot } from './translation';

type ChatBotProps = {
  locale: string;
  defaultLocale: string;
};

const ChatBot = ({ locale, defaultLocale }: ChatBotProps) => {
  const activeLocale = locale || defaultLocale

  return (
    <IntlProvider
      locale={activeLocale}
      defaultLocale={defaultLocale}
      messages={translationsChatBot[activeLocale]}
    >
      <ChatBotContent />
    </IntlProvider>
  );
};

export default memo(ChatBot);
