import { Language, Locale } from './types'

const LanguageToLocaleMap = new Map<Language, Locale>([
  ['no', 'nb-NO'],
  ['en', 'en-GB'],
  ['sv', 'sv-SE'],
  ['da', 'da-DK']
])

export function getLocaleByLanguage(language?: Language): Locale {
  return LanguageToLocaleMap.get(language) || 'nb-NO'
}

export function getLanguageByLocale(locale: string): Language {
  return [...LanguageToLocaleMap].find((i) => i[1] === locale)?.[0] || 'no'
}