import { createContext } from 'react';
import { ChatBotOpenState, ChatBotOpenStateType } from './types';

type ChatBotStateContextValue = {
  chatFrameUrl: string;
  customerSegment?: 'private' | 'corporate';
  openState: ChatBotOpenState;
};

const ChatBotStateContext = createContext<ChatBotStateContextValue>({
  chatFrameUrl: '',
  openState: {
    type: ChatBotOpenStateType.CLOSED,
    isNew: true,
  },
});

export default ChatBotStateContext;
