export const translationsChatBot = {
  'en-GB': {
    'chatbot.minimise': 'Minimize',
    'chatbot.close': 'Close'
  },
  'nb-NO': {
    'chatbot.minimise': 'Minimer',
    'chatbot.close': 'Lukk'
  },
  'sv-SE': {
    'chatbot.minimise': 'Minimera',
    'chatbot.close': 'Stäng'
  }
}
