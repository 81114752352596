export function enableBodyScroll() {
  const $body = !!document && document.querySelector('body')
  $body && ($body.style.overflow = 'scroll')
}
export function disableBodyScroll() {
  const $body = !!document && document.querySelector('body')
  $body && ($body.style.overflow = 'hidden')
}

export function disableBodyScrollOnMobileAndTablet(isMobile) {
  if (isMobile) {
    disableBodyScroll()
  }
}

export function enableBodyScrollOnMobileAndTablet(isMobile) {
  if (isMobile) {
    enableBodyScroll()
  }
}
