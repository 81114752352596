import React from 'react'
import { CacheProvider } from '@emotion/react'
import createEmotionCache from '@emotion/cache'

// This ensures we processes also the css prop during build
// More into in the docs: https://emotion.sh/docs/ssr#gatsby
const createCacheInstance = () =>
  createEmotionCache({
    // key value is dictated by the library
    // https://github.com/emotion-js/emotion/blob/main/packages/cache/src/index.js#L56
    key: 'css',
  })
const emotionCache = createCacheInstance()

type EnvType = 'browser' | 'ssr'

const getRootProvider = (type: EnvType = 'browser') => {
  const wrapper = ({ element }) => (
    <CacheProvider value={type === 'browser' ? emotionCache : createCacheInstance()}>
      {element}
    </CacheProvider>
  )
  return wrapper
}

export default getRootProvider
