export const getNavigationType = (): NavigationType => {
  const navigationEntry = window.performance?.getEntriesByType?.(
    'navigation'
  )?.[0] as PerformanceNavigationTiming;

  if (navigationEntry?.entryType === 'navigation') {
    // Use modern performance navigation timing if available
    return navigationEntry.type;
  } else {
    // Fall back to deprecated performance navigation
    const navigation = window.performance?.navigation;

    if (navigation?.type) {
      switch (navigation.type) {
        case navigation.TYPE_NAVIGATE:
          return 'navigate';
        case navigation.TYPE_BACK_FORWARD:
          return 'back_forward';
        case navigation.TYPE_RELOAD:
          return 'reload';
      }
    }
  }

  return null;
};
